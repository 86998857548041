<template>
  <div>
    <div v-if="show" class="t_header">
      <div class="header">
        <img class="go_back" src="@/static/images/my_index/back_black.png" alt @click="go_back()">
        <h2 class="title">用户协议</h2>
      </div>
    </div>
    <div class="main" v-html="agreement"></div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import tool from '@/utils/tools.js'
import heads from '@/compontens/heads.vue'
import { getPayProtocol } from '@/api/regInfo'
import WechatState from '@/utils/isWechatState.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      agreement: '',
      show: true,
      agency_id: Cookies.get('agency_id')
    }
  },
  created() {
    this.getPayProtocol()
  },
  mounted() {
    this.isWxApplet()
  },
  methods: {
    // 判断是否在微信小程序中
    isWxApplet() {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 必须在WeixinJSBridge回调后进行判断，否则小程序环境检测会不准确
        if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
          this.show = false
          // document.addEventListener('WeixinJSBridgeReady', webchatReady, false)
        } else {
          this.ready()
        }
      } else {
        console.log('不在微信浏览器内-协议页面')
      }
    },
    ready() {
      if (window.__wxjs_environment === 'miniprogram') {
        console.log('在小程序内-协议页面')
        this.show = false
      } else {
        console.log('不在小程序内-协议页面')
        this.show = true
      }
    },
    getPayProtocol() {
      var info = {
        type: 4
      }
      const origin = window.location.origin
      if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
        info.zkb_pro = 1
      }
      getPayProtocol(info).then(res => {
        console.log(res, 'res')
        this.agreement = res.result.agreement
      })
    },
    go_back() {
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler(
            'vue_backApp'
            , '返回'
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
        return
      }
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.main{
  font-size: 32px;
  padding: 30px;
}
.t_header {
  height: 120px;
}
.header {
  width: 100%;
  height: 120px;
  background-color: #ffffff;
  // position: relative;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #eee;
}
.go_back {
  width: 68px;
  height: 68px;
  position: absolute;
  bottom: 30px;
  left: 10px;
  float: left;
}
.title {
  font-weight: 700;
  color: #333;
  text-align: center;
  margin: 0 auto;
  height: 120px;
  line-height: 120px;
}
/deep/.zhul_tk_title{
  font-size: 52px;
  font-weight: 600;
}
/deep/.zhul_tk_toptitle{
  margin: 20px 0;
}
/deep/.zhul_tk_textarea{
  width: 100%;
  height: calc(100vh - 410px);
}
/deep/a{
  display: none;
}
</style>
